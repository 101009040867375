import React, { useEffect, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import HeroImage from "../../../Assets/Images/other/about-us.png";
import languages from "../../../Assets/data/languages.json";
import { use } from "react";

function HeroSection({language}) {
  const theme = useTheme();
  
  const isMobile = useMediaQuery({ maxWidth: 650 });
  const isTablet = useMediaQuery({ minWidth: 651, maxWidth: 1023 });
  const isDesktop = useMediaQuery({ minWidth: 1024 });
  const isSmallScreen = useMediaQuery({ maxWidth: 1023 });

  const getResponsiveValue = (mobileSize, tabletSize, defaultSize) => {
    if (isMobile) return mobileSize;
    if (isTablet) return tabletSize;
    return defaultSize;
  };

  const [selectedLanguage, setSelectedLanguage] = useState(languages.en);
  const [sectionData, setSectionData] = useState(languages.en.AboutUs.HeroSection);

  useEffect(() => {
    const newLanguage =
      language.language === "EN" ? languages.en : languages.pt;
    setSelectedLanguage(newLanguage);
    setSectionData(newLanguage.AboutUs.HeroSection);
  }, [language]);



  const styles = {
    main: {
      display: "flex",
      flexDirection: "column ",
      alignItems: "center",
      height: getResponsiveValue("125vh", "150vh", "97vh"),
      width: getResponsiveValue("100vw", "30vw", "100vw"),
      justifyContent: "center",
      position: "relative",
      top: getResponsiveValue("100%","100%","15vh"),
      
    },
    
    aboutUs:{
      //position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#732043",
      borderRadius: "15px",
      width: getResponsiveValue("90vw","90vw","80vw"),
      height: getResponsiveValue("60vh","17vh","20vh"),
      padding: "1vw",
      zIndex: 1,
      fontWeight: 650,
      fontSize: getResponsiveValue("16vw", "17vw", "14vw"),
      letterSpacing: "1.1vw",
      opacity: "15%",
      whiteSpace: "nowrap",
      position: "relative",

    },
    back: {
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: getResponsiveValue("60vh", "60vh", "90vh"),
      width: getResponsiveValue("90vw", "90vw", "80vw"),
      //padding: getResponsiveValue("0rem", "0rem", "3.5rem"),
      borderRadius: "0px 30px 0px 30px",
      //position: "relative",
    },
    
    headline1: {
        color: theme.palette.secondary.main,
        fontSize: getResponsiveValue("1.2rem", "3vw", "2vw"),
        height:getResponsiveValue("3vh","3vh","3.5vh"),
        font: "Roboto",
    },
    headline2: {
        color: theme.palette.primary.main,
        display: "flex",
        position: "relative",
        height:getResponsiveValue("1vh","5vh","5vh"),
        fontWeight: "bold",
        fontSize: getResponsiveValue("4.5vw", "4vw", "3vw"),
        font: "Raleway",
          
    },
    description: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        color: "#052533",
        fontWeight: 300,
        height:getResponsiveValue("25vh","24vh","22vh"),
        
        fontSize: getResponsiveValue("2.6vw", "2vw", "1.4vw"),
        lineHeight: getResponsiveValue("2.3vh", "2.8vh", "3.6vh"),
        fontSmooth: "Roboto",
        textAlign: "justify",
        position: "relative",
        marginRight: getResponsiveValue("43vw","45vw","40vw"),
        marginTop: getResponsiveValue("4vh","3vh","3.4vh"),
      
    },
    stats: {
      display: "flex",
      alignItems:"center", 
      justifyContent:"center",
      whiteSpace: "nowrap",
      height: getResponsiveValue("60vh", "60vh", "30vh"),
      flexDirection: isSmallScreen ? "column" : "row",
    
    },
    grad1:
    {
      position: "absolute", 
      top: getResponsiveValue("12vw", "7vw", "-10vw"), 
      left: getResponsiveValue("-30vw", "-23vw", "-17vw"), 
      width: getResponsiveValue("90vw", "90vw", "60vw"),
      height: getResponsiveValue("90vw", "70vw", "70vw"),
      background:
        "radial-gradient(circle, rgba(116, 33, 68, 0.30) 20%, rgba(172, 108, 135, 0.15) 50%, rgba(255, 255, 255, 0.001) 60%)",
      borderRadius: "50%", // Make it a perfect circle
      zIndex: 1, // Below the main content
    },
    grad2: {
      position: "absolute", 
      top: getResponsiveValue("20vw", "25vw", "-10vw"), 
      right: getResponsiveValue("-35vw", "-117vw", "-30vw"), 
      width: getResponsiveValue("70vw", "100vw", "70vw"), 
      height: getResponsiveValue("70vw", "70vw", "90vw"),  
      background:
        "radial-gradient(circle, rgba(116, 33, 68, 0.30) 20%, rgba(172, 108, 135, 0.15) 50%, rgba(255, 255, 255, 0.001) 60%)",
      borderRadius: "50%", // Make it a perfect circle
      zIndex: 0, // Below the main content
      
    }
  }

  return (
    <div
      style={styles.main}
    >
        <div
          style={styles.back}
        > 
          <div
            style={{
              display: "flex",
              flexDirection: getResponsiveValue("column", "column", "row"),
              justifyContent: getResponsiveValue(
                "center",
                "center",
                "space-between"
              ),
              alignItems: getResponsiveValue(
                "flex-start",
                "flex-start",
                "center"
              ),
              height: getResponsiveValue("auto", "auto", "43vh"),
            }}
          >
            {!isDesktop && (
              <div style={{
                width: getResponsiveValue("40vw", "40vw", "50vw"),
                height:getResponsiveValue("10vh","40vh","10vh"),
                display: "flex",
                position: "relative",
                top: getResponsiveValue("33vh", "21vh", "0vh"),
                right: getResponsiveValue("-55vw", "-90vw", "0vw"),
              }}
                >
              <img
                src={HeroImage}
                alt="Hero"
                style={{
                  width: getResponsiveValue("36vw", "38vw", "47%"),
                  height: getResponsiveValue("37vh","40vh","70%"),
                  position: "absolute",
                  borderRadius: "30px",
                  objectFit: "cover",
                  zIndex: 2,
                  opacity: 0.75,  
                }}
              />
              <div
              style={{
                position: "absolute",
                width: getResponsiveValue("36vw", "38vw", "45%"),
                height: getResponsiveValue("37vh","40vh","70%"),
                borderRadius: "30px",
                backgroundColor: "rgba(195,122,155, 0.4)",
                zIndex: 4,
                //top: getResponsiveValue("21vw","25vw","10vw"),
                //left: getResponsiveValue("55vw","52vw","44.3vw"),
                
              }}
              ></div>
              </div>
            )}
          
            <div style={{
              position: "relative",
              top: getResponsiveValue("-8.5vh", "-27vh", "19vh"),
              left: getResponsiveValue("0vw", "35vw", "1vw"),
             }}>
              <div style={styles.aboutUs}>
              {sectionData.aboutUsTag}
              </div>
              <div style= {{
                position: "relative",
                top: getResponsiveValue("-30vh", "-7vh", "-7vh"),
                left : getResponsiveValue("1vw", "5vw", "-1vw"),
              }}>
              <Typography
                variant="h4"
                style={styles.headline1}
              >
                {sectionData.headline1}
              </Typography>
              <Typography 
                variant="h3"
                style={styles.headline2}
              >
                {sectionData.headline2}
              </Typography>
              <Typography
                variant="h3"
                style={styles.description}
              >
                {sectionData.description}
              </Typography>
              <div style={
                styles.stats
                }> 
              
              {sectionData.stats.map((stat, index) => 
              <div key={index} style = {{
                height: getResponsiveValue("10vh", "10vh", "10vh"),
                padding: getResponsiveValue("2rem" , "2rem", "2rem"),
                textAlign:"center",
                position: "relative",
                top: getResponsiveValue("15vh", "20vh", "5vh"),
                left: getResponsiveValue("-1vw", "-4vw", "-1vw"),
                flexWrap: "wrap",
                margin: isMobile ? "0.2rem" : "1.5rem",
                
                
                }}>
                <Typography
                  variant="h4"
                  style={{
                    color: "#732043",
                    fontSize: getResponsiveValue("8vw", "5vw", "4vw"),
                    fontWeight: "bold",
                    textAlign: ".center",  
                  }}
                >
                  {stat.value}
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    color: "#732043",
                    fontSize: getResponsiveValue("4vw", "3vw", "1.5vw"),
                    textAlign: "center", 
                  }}
                > 
                  {stat.description}
                  </Typography>
                </div>
              )}
              </div>
            </div>
            </div>
            </div>
            </div>

            {
            isDesktop && (
              <div style={{
                width: getResponsiveValue("100%", "100%", "70vw"),
                height:getResponsiveValue("7vh","70vh","100vh"),
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                
                top: getResponsiveValue("0vh", "0vh", "-37vh"),
                left: getResponsiveValue("0vw", "0vw", "24vw"),

              }}
                >
              <img
                src={HeroImage}
                alt="Hero"
                style={{
                  width: getResponsiveValue("100%", "100vw", "38vw"),
                  height: getResponsiveValue("120%","120vh","42vh"),
                  //maxHeight: "70vh",
                  borderRadius: "30px",
                  objectFit: "cover",
                  zIndex: 1,
                  opacity: 0.75,}}
              />
              <div
              style={{
                width: getResponsiveValue("100%", "10vw", "38vw"),
                height: getResponsiveValue("120%","50vh","42vh"),
                borderRadius: "30px",
                backgroundColor: "rgba(195,122,155, 0.4)",
                zIndex: 2,
                position: "absolute",
                left: getResponsiveValue("0vw", "0vw", "16vw"),
              }}
              />
            </div>
              
            )}
            <div style={styles.grad1}/>  
            <div style={styles.grad2}/>
    </div>
  );
}


export default HeroSection;