import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { Typography, IconButton, Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import ContactUs from "./ContactUs";
import InnovaLogo from "../../../Assets/Images/OurLogos/logos-03.png";
import languages from "../../../Assets/data/languages.json";
import HeroImage from "../../../Assets/Images/other/servicesPagePhotoDesk.webp";
import Drawing1 from "../../../Assets/Images/other/servicesHeroSectionDrawing1.svg";
import Drawing2 from "../../../Assets/Images/other/servicesHeroSectionDrawing2.svg";

const CONTAINER_STYLES = (isMobile, isTablet) => ({
  position: "relative",
  minHeight: isMobile ? "60vh" : isTablet ? "25vh" : "40vh",
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: isMobile ? "center" : "space-around",
  alignItems: "center",
  background: "#E9E6E6",
  padding: isMobile ? "1.0rem" : "0.5rem",
  marginTop: "5.5rem",
});

const TEXT_CENTERED = (isMobile) => ({
  textAlign: isMobile ? "center" : "left",
  //maxWidth: "75rem",
  marginLeft: isMobile ? "0" : "0rem",
  
});

const TITLE_STYLES = (isMobile, isTablet) => ({
  fontSize: isMobile ? "1.9rem" : isTablet ? "2.5rem" : "3rem",
  fontWeight: "bold",
  color: "#65172C",
  lineHeight: isMobile ? "3.1rem" : "4.2rem",
  maxWidth: isMobile ? "18rem" : "30rem",
  margin: "0 auto",
  alignItems: "left"

});

const SUBTITLE_STYLES = (theme, isMobile, isTablet) => ({
  fontSize: isMobile ? "1.9rem" : isTablet ? "2.5rem" : "3rem",
  color: theme.palette.secondary.main,
  lineHeight: isMobile ? "0.8" :"2.1rem",
  maxWidth: isMobile ? "14.88rem" : "27rem",
  margin: isMobile ? "0 auto" : "0 auto",
});

const DESCRIPTION_STYLES = (theme, isMobile, isTablet) => ({
  fontSize: isTablet ? "0.9rem" : "0.9rem",
  color: theme.palette.secondary.main,
  //marginTop: isMobile ? "1.6rem" : "0rem",
  lineHeight: isMobile ? "1.2rem" : "1.1rem",
  maxWidth: isMobile ? "20rem" : isTablet ? "22rem" : "26rem", // Add this line to limit the width
  margin: "0 auto", // Center the text
});

const BUTTON_STYLES = (theme) => ({
  marginTop: "1.5rem",
  padding: "10px 22px",
  background: theme.palette.primary.main,
  color: "white",
  border: "none",
  borderRadius: "50px",
  fontWeight: "bold",
  fontSize: "1rem",
  cursor: "pointer",
});

const IMAGE_CONTAINER = (isMobile) => ({
  position: "relative",
  width: isMobile ? "70%" : "35%",
  textAlign: "left",
  marginTop: isMobile ? "1.0rem" : "0rem",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",

});

const HERO_IMAGE_CONTAINER = (isMobile) => ({
  borderRadius: isMobile ? "38px" : "18px",
  backgroundColor: "#c37a9b",
  maxWidth: isMobile ? "40rem" : "38rem",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 3,
});

const HERO_IMAGE_STYLES = (isMobile) => ({
  width: "100%",
  height: "auto",
  borderRadius: isMobile ? "38px" : "18px",
  opacity: 0.5,
  overflow:"hidden",
});

const DRAWING_STYLES = (position) => ({
  position: "absolute",
  width: "5rem",
  zIndex: 1,
  ...position,
});

const STATS_CONTAINER = (isMobile) => ({
  display: "flex",
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  textAlign: "center",
  //marginTop: isMobile ? "1rem" : "5.7rem",
  margin: "0 auto",
  gap: isMobile ? "1rem" : "2.2rem", // Added gap to increase spacing between elements
});

function NewHeroSection({ language = { language: "EN" } }) {
  const theme = useTheme();
  const isMobile = useMediaQuery({ maxWidth: 620 });
  const isTablet = useMediaQuery( { minWidth: 621, maxWidth: 910 });
  
  const [selectedLanguage, setSelectedLanguage] = useState(languages.en);
  const [sectionData, setSectionData] = useState(languages.en.Services.HeroSection);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [emailWasSent, setEmailWasSent] = useState(false);

  useEffect(() => {
    const newLanguage = language.language === "EN" ? languages.en : languages.pt;
    setSelectedLanguage(newLanguage);
    setSectionData(newLanguage.Services.HeroSection);
  }, [language]);

  const toggleDialog = () => {
    setIsDialogOpen((prev) => !prev);
    setEmailWasSent(false);
  };

  return (
    <div>
      <div style={{marginBottom: isMobile ? "2.5rem" : "4.5rem"}}>
        <div style={CONTAINER_STYLES(isMobile, isTablet)}>
          <div style={TEXT_CENTERED(isMobile)}>
            <Typography style={TITLE_STYLES(isMobile, isTablet)}>Building Bridges</Typography>
            <Typography style={SUBTITLE_STYLES(theme, isMobile, isTablet)}>
              between <span style={{ fontWeight: "bold"}}>You</span> 
            </Typography> 
            <Typography style={{marginBottom: "1.3rem"}}>
            <span style={{fontWeight: "normal", fontSize: isMobile ? "1.9rem" : isTablet ? "2.5rem" : "3rem",}}>and </span> 
            <span style={{fontWeight: "bold", fontSize: isMobile ? "1.9rem" : isTablet ? "2.5rem" : "3rem",}}>Customers</span>
              <img src={InnovaLogo} alt="Innova Logo" style={{ width: "20px", height: "20px", marginLeft: "4px", verticalAlign: "text-bottom" }} />
            </Typography>
            
            <Typography style={DESCRIPTION_STYLES(theme, isMobile, isTablet)}>
              Business ideas at the right price. Challenge everything. Keeping advertising standards high. Research-based advertising for the bulls.
            </Typography>
            <button style={BUTTON_STYLES(theme)} onClick={toggleDialog}>CONTACT US</button>
          </div>

          <Dialog open={isDialogOpen} onClose={toggleDialog} maxWidth="xs" fullWidth>
            <DialogContent>
              {!emailWasSent ? (
                <ContactUs type="newsletter" />
              ) : (
                <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <IconButton style={{ backgroundColor: theme.palette.primary.main, color: "white", padding: "10px" }}>
                    <EmailIcon />
                  </IconButton>
                  <Typography variant="h6" style={{ color: theme.palette.primary.main }}>Email Sent Successfully!</Typography>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} color="secondary">Close</Button>
            </DialogActions>
          </Dialog>

          <div style={IMAGE_CONTAINER(isMobile)}>
            <div style={HERO_IMAGE_CONTAINER(isMobile)}>
              <img src={HeroImage} alt="Hero Section" style={HERO_IMAGE_STYLES(isMobile)} />
            </div>
            <img src={Drawing1} alt="Decorative Drawing 1" style={DRAWING_STYLES({ bottom: "-22px", left: "-30px", opacity: 0.8 })} />
            <img src={Drawing2} alt="Decorative Drawing 2" style={DRAWING_STYLES({ top: "-24px", right: "-30px", opacity: 0.8 })} />
          </div>

        </div>
      </div>
      <div style={STATS_CONTAINER(isMobile)}>
        {sectionData.stats.map((stat, index) => (
          <div key={index} style={{ marginBottom: "1.5rem" }}>
            <Typography variant="h4" style={{ color: "#732043", fontSize: "2.4rem", fontWeight: "bold" }}>{stat.value}</Typography>
            <Typography variant="h6" style={{ color: theme.palette.secondary.main, fontSize: "1.3rem" }}>{stat.description}</Typography>
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewHeroSection;