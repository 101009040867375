import React from "react";

import Industries from "./Components/Industries";
import NewHeroSection from "./Components/NewHeroSection";
import SegmentedButton from "./Components/SegmentedButtonSection";
import PortfolioSection from "./Components/PortfolioSection";
import ProjectTimeline from "./Components/ProjectTimeline";
import SoftwareSolutions from "./Components/SoftwareSolutions";
import ProjectsSection from "./Components/ProjectsSection";
import ContactUs from "./Components/ContactUs";

const Services = () => {
  return (
    <main
      style={{
        overflow: "hidden",
        //background: "#F0F0F0", 
        background: "#E9E6E6" // No figma foi usada esta cor, por isso mudei
      }}
    >
      <NewHeroSection />
      <ProjectsSection />
      <SegmentedButton />
      <ProjectTimeline />
      <Industries />
      <ContactUs />
      <PortfolioSection />
    </main>
  );
};

export default Services;
