import React from "react";
import { Typography, Button } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import imagePath from "../../../Assets/Images/InnovationWeek/IW_2.png";

const InnovationWeekSection = () => {
    const isMobile = useMediaQuery({ maxWidth: 768 });

    return (
        <section
            style={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "center" : "flex-start",
                justifyContent: "space-between",
                background: "linear-gradient(to right, #E4C5D5, #742144)",
                borderRadius: "20px",
                padding: isMobile ? "2rem 1.5rem" : "2rem 3rem",
                maxWidth: "72%",
                margin: "3rem auto",
                color: "white",
                textAlign: isMobile ? "center" : "left",
            }}
        >
            {/* IMAGE (Left side) */}
            <div
                style={{
                    flex: isMobile ? "0 0 auto" : "0 0 50%",
                    width: isMobile ? "100%" : "50%",
                    marginBottom: isMobile ? "1.5rem" : 0,
                    marginTop: isMobile ? "1.5rem" : "0.3rem",
                }}
            >
                <img
                    src={imagePath}
                    alt="Innovation Week"
                    style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                />
            </div>

            {/* TEXT (Right side) */}
            <div
                style={{
                    flex: isMobile ? "0 0 auto" : "0 0 40%",
                    width: isMobile ? "100%" : "50%",
                    marginBottom: isMobile ? "1.5rem" : 0,
                    marginTop: isMobile ? "1.5rem" : "0.8rem",
                    padding: isMobile ? "0 1rem" : "0 2.5rem",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                {/* Large translucent background text */}
                <Typography
                    component="div"
                    style={{
                        position: "absolute",
                        top: isMobile ? "-10px" : "-30px",
                        left: 40,
                        fontSize: isMobile ? "3rem" : "4.5rem",
                        color: "rgba(255, 255, 255, 0.05)",
                        fontWeight: "bold",
                        lineHeight: 1,
                        zIndex: 1,
                        pointerEvents: "none",
                        userSelect: "none",
                    }}
                >
                    OUR WEEK
                </Typography>

                {/* Main text container (above the background text) */}
                <div style={{ position: "relative", zIndex: 2 }}>
                    <Typography
                        variant="h4"
                        style={{
                            fontWeight: 500,
                            fontSize: isMobile ? "1.6rem" : "2rem",
                            marginBottom: "0.4rem",
                            opacity: "50%",
                        }}
                    >
                        Our week,
                    </Typography>
                    <Typography
                        variant="h3"
                        style={{
                            fontWeight: "bold",
                            fontSize: isMobile ? "2rem" : "3.8rem",
                            color: "#F3E8DB",
                            lineHeight: 1.2,
                        }}
                    >
                        INNOVATION WEEK
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{
                            margin: "1rem 0",
                            fontSize: isMobile ? "1rem" : "1rem",
                            color: "#E4C5D5",
                            lineHeight: 1.5,
                        }}
                    >
                        Explore the concept of innovation with renowned personalities, top-tier companies, and pioneering projects showcased in the event
                    </Typography>

                    <Typography
                        variant="body1"
                        style={{
                            marginBottom: "1.2rem",
                            fontSize: isMobile ? "1rem" : "1rem",
                            color: "#E4C5D5",
                            lineHeight: 1.5,
                        }}
                    >
                        Do you want to be a part of this huge event?
                    </Typography>
                    {/*
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#732043",
                            color: "white",
                            padding: "8px 60px",
                            fontWeight: "bold",
                            fontSize: isMobile ? "1rem" : "0.8rem",
                            borderRadius: "25px",
                        }}
                    >
                        BE OUR PARTNER
                    </Button> */}
                </div>
            </div>
        </section>
    );
};

export default InnovationWeekSection;