import { useState } from "react";
import { IconButton, useTheme, useMediaQuery } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Importing images for different services
import SoftwareImg from "../../../Assets/Images/HomePageImages/SoftwareSolutions.png";
import InnovaMobileAppImg from "../../../Assets/Images/HomePageImages/MobileDevelopment.png";
import RoboticsPrototypingImg from "../../../Assets/Images/HomePageImages/RoboticsPrototyping.png";

// Importing icons for different services
import WebDevThemeIcon from "../../../Assets/Images/Icons/webdevtheme-icon.svg";
import MobileDevThemeIcon from "../../../Assets/Images/Icons/mobiledevtheme-icon.svg";
import RoboticsPrototypingThemeIcon from "../../../Assets/Images/Icons/roboticsandprototypingtheme-icon.svg";

// Array containing information about each service
const services = [
  {
    title: "Web Development",
    intro: "At In-Nova we do",
    content: "With tailored solutions and cutting-edge technologies, we craft impactful software that propels businesses forward.",
    image: SoftwareImg,
    icon: {default: WebDevThemeIcon },
    projects: [
      { title: "Project name", image: SoftwareImg, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula viverra leo id aliquam. Sed ullamcorper nulla nibh, id pulvinar eros ultrices maximus." },
      { title: "Project 2", image: SoftwareImg, description: "Project 2 details..." }
    ]
  },
  {
    title: "Mobile Development",
    intro: "At In-Nova we do",
    content: "In-Nova creates intuitive apps that redefine user experiences and seamless interactions on various devices, empowering digital businesses.",
    image: InnovaMobileAppImg,
    icon: {default: MobileDevThemeIcon },
    projects: [
      { title: "Project 3", image: InnovaMobileAppImg, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula viverra leo id aliquam. Sed ullamcorper nulla nibh, id pulvinar eros ultrices maximus." },
      { title: "Project 4", image: InnovaMobileAppImg, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula viverra leo id aliquam. Sed ullamcorper nulla nibh, id pulvinar eros ultrices maximus." }
    ]
  },
  {
    title: "Robotics & Prototyping",
    intro: "At In-Nova we do",
    content: "Exploring the frontier of robotics and prototyping. In-Nova pioneers innovative solutions, bringing futuristic concepts to life.",
    image: RoboticsPrototypingImg,
    icon: {default: RoboticsPrototypingThemeIcon },
    projects: [
      { title: "Project 5", image: RoboticsPrototypingImg, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula viverra leo id aliquam. Sed ullamcorper nulla nibh, id pulvinar eros ultrices maximus." },
      { title: "Project 6", image: RoboticsPrototypingImg, description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vehicula viverra leo id aliquam. Sed ullamcorper nulla nibh, id pulvinar eros ultrices maximus." }
    ]
  }
];

export default function ServicesSection() {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(max-width: 1024px)");

  // State for tracking the selected service area
  const [selectedArea, setSelectedArea] = useState("Web Development");

  // State for tracking the displayed project index for each service
  const [projectIndexes, setProjectIndexes] = useState({
    "Web Development": 0,
    "Mobile Development": 0,
    "Robotics & Prototyping": 0
  });

  // Function to change the project being displayed in the selected service
  const handleProjectChange = (serviceTitle, direction) => {
    setProjectIndexes((prevIndexes) => {
      const maxIndex = services.find(s => s.title === serviceTitle).projects.length - 1;
      let newIndex = prevIndexes[serviceTitle] + direction;
      if (newIndex < 0) newIndex = maxIndex;
      if (newIndex > maxIndex) newIndex = 0;
      return { ...prevIndexes, [serviceTitle]: newIndex };
    });
  };


  const outerDivStyles = {
    width: isMobile ? "90%": "100%",
    margin: isMobile ? "0 auto": "0px",
    marginBottom: "4vh",
    background: "#E9E6E6"
  }

  const buttonsDivStyles = { 
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    gap: isMobile ? 0 : "55px",
    marginTop: "50px",
    marginBottom: isMobile ? "20px" : "0",
    alignItems: "center"
  }

  const eachButtonStyles = (service, index) => ({
    cursor: "pointer",
    width: isMobile ? "100%" : "45%",
    height: isMobile ? "4.5rem" : "7.6rem",
    background: selectedArea === service.title ? theme.palette.primary.main : "white",
    color: selectedArea === service.title ? "white" : theme.palette.primary.main,
    border: "0px solid #ccc",
    borderRadius: (() => {
      if (isMobile) {
        if (index === 0) return "20px 20px 0 0";
        if (index === 2) return "0 0 20px 20px";
        return "0 0 0 0";
      }
      return "20px 20px 0 0";
    })(),

    display: "flex",
    flexDirection: isMobile ? "row" : "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "8px",
    fontWeight: "bold",
    fontSize: isMobile ? "1.2rem" : "1.4rem",
  });

  const departmentIconStyles = (service) => ({ 
    width: (() => {
      if (isMobile) return "4vh";
      if (isTablet) return "7vh";
      return "8vh";
    })(),
    height: (() => {
      if (isMobile) return "4vh";
      if (isTablet) return "7vh";
      return "8vh";
    })(),
    filter: selectedArea === service.title 
      ? "brightness(0) invert(1)"  // Turns black icons white
      : "none",  // Default appearance
    transition: "filter 0.3s ease"
  });

  const eachDepartmentDivStyles = { 
    flexDirection: isMobile ? "column" : "row",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    padding: isMobile ? "3.1vh" : "5vh",
    borderRadius: isMobile ? "20px 20px 20px 20px" : "0 0 10px 10px",
    color: "white",
    overflow: "hidden",
    height: isMobile ? "60vh" : "65vh",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
  }

  const departmentTextDivStyles = {
    width: isMobile ? "100%" : "35%",
    height: isMobile ? "45%" : "85%",
    overflow: "hidden",
    marginTop: isMobile ? "10px" : "30px"
  }

  const departmentTextIntroStyles = {
    color: "#C37A9B",
    fontSize: isMobile ? "20px" : "32px"
  }

  const departmentTextTitleStyles = {
    fontSize: isMobile ? "30px" : "36px"}

  const departmentTextDescriptionStyles = {
    color:"#E4C5D5",
    marginTop:"20px",
    fontSize: isMobile ? "15px" : "14px"
  }

  const backgroundTextStyles = {
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: isTablet ? "3.5rem" : "5.1rem",
    fontWeight: "bold",
    color: "#C37A9B",
    opacity: isMobile ? 0 : "10%",
    zIndex: "0", 
    textAlign: "center",
    letterSpacing: isTablet ? "0.3rem": "0.6rem",
    whiteSpace: "nowrap",
    fontFamily:"Exo 2",
    textTransform: "uppercase",
  }

  const eachProjectDivStyles = {
    height: isMobile ? "55%" : "85%",
    width: isMobile ? "100%" : "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative"
  }

  const leftArrowStyles = {
    position: "absolute",
    left: 0,
    color: "#C37A9B", 
    width: isMobile ? "2.5vh" : "5vh",
  }

  const rightArrowStyles = {
    color: "#C37A9B",
    width: isMobile ? "2.5vh" : "5vh",
    position: "absolute", 
    right: 0 
    }

  const projectImageStyles = {
    height: isMobile ? "18vh" : "35vh", // Set a fixed height
    position: "relative",
    marginTop: isMobile ? "30px" : "0px",
    marginBottom: isMobile ? "8px" : 0
  }
  const projectTitleStyles = {
    fontSize: "1.4rem"
  }
  const projectDescriptionStyles = {
    maxWidth: isMobile ? "90%" : "70%",
    margin: "0 auto",
    marginTop:"3px",
    position: "relative",
    color:"#E4C5D5",
    fontSize: isMobile ? "0.85rem" : "0.9rem"
  }

  return (
    <div style={outerDivStyles}>
      {/* Buttons for selecting a service */}
      <div style={buttonsDivStyles}>
        {services.map((service, index) => (
          <button
            key={service.title}
            style={eachButtonStyles(service, index)}
            onClick={() => setSelectedArea(service.title)}
          ><img 
              src={service.icon.default} 
              alt={`${service.title} icon`} 
              style={departmentIconStyles(service)} 
          />
            {service.title}
          </button>
        ))}
      </div>

      {/* Service details and project showcase */}
      <div style={eachDepartmentDivStyles}>
        <div style={departmentTextDivStyles}>
          <p style={departmentTextIntroStyles}
          >{services.find(s => s.title === selectedArea)?.intro}</p>
          <h2 style={departmentTextTitleStyles} className="text-3xl font-bold">{selectedArea}</h2>
          <p style={departmentTextDescriptionStyles} className="mt-4 text-lg">{services.find(s => s.title === selectedArea)?.content}</p>
        </div>

        {/* Background Text */}
        <div style={backgroundTextStyles}>
          {selectedArea}
        </div>

        {/* Project image and details with navigation buttons */}
        <div style={eachProjectDivStyles}>

          <IconButton style={{   
            }} onClick={() => handleProjectChange(selectedArea, -1)}>
            <ArrowBackIcon style={leftArrowStyles} />
          </IconButton>
          <div style={{ 
            textAlign: "center",
            }}>
            <img src={services.find(s => s.title === selectedArea)?.projects[projectIndexes[selectedArea]].image} 
            alt="Project" style={projectImageStyles} />
            <h3 style={projectTitleStyles}>{services.find(s => s.title === selectedArea)?.projects[projectIndexes[selectedArea]].title}</h3>
            <p style={projectDescriptionStyles}
              >{services.find(s => s.title === selectedArea)?.projects[projectIndexes[selectedArea]].description}</p>
          </div>
          <IconButton onClick={() => handleProjectChange(selectedArea, 1)}>
            <ArrowForwardIcon style={rightArrowStyles} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}