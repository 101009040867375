import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "react-responsive";

export const ContactUs = ({ type }) => {
  const form = useRef();
  const theme = useTheme();

  const isMobile = useMediaQuery({ maxWidth: 600 });

  // Controlled inputs (better reactivity)
  const [formData, setFormData] = useState({
    to_name: "",
    user_email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_yb2gtvd", "template_1z75y5s", form.current, {
        publicKey: "bU18izKnnYVyMNe86",
      })
      .then(
        () => {
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div
      style={{
        background: theme.palette.primary.main,
        padding: "40px 10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <form
        ref={form}
        onSubmit={sendEmail}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          maxWidth: "auto",
          width: "100%",
          color: "gray",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", padding: "20px", gap: "10px" }}>
          <h1 style={{ color: "white", fontSize: isMobile? "1.5rem" : "3.5rem"}}>Curious about our work?</h1>
          <h3 style={{ color: "white", opacity: 0.8, fontSize: isMobile? "1rem" : "1rem"}}>Take a look at our portfolio</h3>
        </div>

        <h4 style={{ color: "white", opacity: 0.8, position: "relative", textAlign: isMobile? "center" : "left", left: isMobile? "0" : "5%", fontSize: isMobile? "0.6rem" : "1rem" }}>
          Fill the form below to receive our portfolio in your inbox.
        </h4>

        {/* Input fields in a responsive layout */}
        <div style={{ position: "relative", display: "flex", flexDirection: isMobile? "column" : "row", alignItems: "center", gap: "10px" }}>
          <input
            type="text"
            name="to_name"
            placeholder="Name"
            value={formData.to_name}
            onChange={handleChange}
            style={{
              flex: 8,
              padding: isMobile ? "5px 15px" : "10px 20px",
              borderRadius: "25px",
              border: "1px solid #ccc",
              fontSize: isMobile? "0.9rem" : "1rem",
              width: isMobile? "80%" : "100%",
              background: "white",
              opacity: isMobile? 1 :  0.8,
              position: "relative",
              left: isMobile? "0" : "5%",
              }}
              required
            />
          <input
            type="email"
            name="user_email"
            placeholder="Email"
            value={formData.user_email}
            onChange={handleChange}
            style={{
              flex: 7,
              padding: isMobile ? "5px 15px" : "10px 20px", // Smaller padding on mobile
              borderRadius: "25px",
              border: "1px solid #ccc",
              fontSize: isMobile? "0.9rem" : "1rem",
              width: isMobile? "80%" : "100%",
              position: "relative",
              right: isMobile? "0" :"5%",
              zIndex: 2,
              background: "white",
            }}
            required
          />
          {/* Submit Button */}
          <button
            type="submit"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: "white",
              padding: isMobile? "5px 25px" : "12px 40px",
              borderRadius: "25px",
              border: "none",
              cursor: "pointer",
              fontSize: isMobile? "0.9rem" : "1.1rem",
              transition: "0.3s",
              position: "relative",
              width: isMobile? "90%" : "auto",
              right: isMobile? "0" : "5%",
            }}
            >
            Send me ➔
          </button>
        </div>

        {/* Conditional Message Field */}
        {type === "message" && (
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            onChange={handleChange}
            style={{
              padding: "10px",
              borderRadius: "10px",
              fontSize: "1rem",
              width: "100%",
              resize: "vertical",
              height: "100px",
            }}
            required
          />
        )}
      </form>
    </div>
  );
};

export default ContactUs;